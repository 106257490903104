import React from 'react';

export const ReloadButton = ({ spinning, onClick }) => (
    <button
        onClick={onClick}
        className="relative ml-auto top-2 right-2 w-12 h-12 flex items-center justify-center bg-blue-500 text-white rounded-full shadow-md hover:bg-blue-600"
    >
        <svg
            className={`w-6 h-6 ${spinning ? 'animate-spin' : ''}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 4v6h6M20 20v-6h-6M16.24 7.76A9 9 0 0012 3.6a9 9 0 00-4.24 1.18M7.76 16.24A9 9 0 0112 20.4a9 9 0 004.24-1.18"
            ></path>
        </svg>
    </button>
);