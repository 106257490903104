import React, {useEffect, useState} from 'react';
import { ReloadButton} from './ReloadButtonComponent';
import { fetchWithCredentials } from './config';

const resetAssistant = async () => {
    try {
        const response = await fetchWithCredentials('/openai/chat/initialize-thread', {
            method: 'POST'
        });
    
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error resetting assistant: ${errorText}`);
        }
    } catch (error) {
        console.error(error.message); // Adjusted error handling
        throw error; // Re-throwing the error to be handled by higher-level code
    }
}

const saveSummarizeChat = async () => {
    try {
        const response = await fetchWithCredentials('/openai/summary/summarize-save-chat', {
            method: 'POST'
        });
    
        if (!response.ok) {
            console.error('Failed to summarize conversation');
        }
    } catch (error) {
        console.error('Error saving summarized chat:', error);
    }
};

export const RestartMessages = ({ clearMessages }) => {
    useEffect(() => {
        resetAssistant();
        return () => {
            saveSummarizeChat();
        };
      }, []);

    const [spinning, setSpinning] = useState(false);


    const resetConversation = async () => {
        setSpinning(true);
        try {
            await saveSummarizeChat();
            await resetAssistant();
            clearMessages();
        } finally {
            setSpinning(false);
        }
    };

    return (
        <ReloadButton spinning={spinning} onClick={resetConversation} />
    );
}