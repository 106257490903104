import React, { useState } from 'react';
import { fetchWithCredentials } from './config';

function Login({ onLogin, onLogout, isLoggedIn }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetchWithCredentials('/usermanager/login', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ username, password })
      });
  
      if (response.status === 200) {
          onLogin();
      } else {
          console.error('Login failed');
      }
    } catch (error) {
        console.error('Error logging in:', error);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetchWithCredentials('/usermanager/logout', {
          method: 'POST',
      });
  
      if (response.ok) {
          onLogout();
          setUsername('');
          setPassword('');
      } else {
          console.error('Logout failed');
      }
    } catch (error) {
        console.error('Error logging out:', error);
    }
  };

  return (
    <div className="flex flex-col items-center p-4">
      {!isLoggedIn ? (
        <form onSubmit={handleLogin}>
          <div className="flex flex-row gap-4 p-4">
            <input
              id="usernameInput"
              type="text"
              placeholder="Användarnamn"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="p-2 border border-gray-400 rounded-md text-black"
            />
            <input
              id="passwordInput"
              type="password"
              placeholder="Lösenord"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="p-2 border border-gray-400 rounded-md text-black"
            />
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md shadow-md"
            >
              Logga in
            </button>
          </div>
        </form>
      ) : (
        <div className="flex items-center space-x-4">
          <span className="text-lg">Välkommen {username}</span>
          <button
            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
            onClick={handleLogout}
          >
            Logga ut
          </button>
        </div>
      )}
    </div>
  );
}

export default Login;
