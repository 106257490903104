import axios from "axios";

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]" ||
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const API_URL = isLocalhost
    ? "http://localhost:5000/api"
    : "http://api.gustavssonconsulting.se/api";

export const Axios = axios.create({
    withCredentials: true,
    baseURL: API_URL
});

export const fetchWithCredentials = async (url, options = {}) => {
    const response = await fetch(`${API_URL}${url}`, {
        ...options,
        credentials: 'include'
    });
    return response;
};