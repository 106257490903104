import React, { useState, useEffect } from 'react';
import { Chat } from './ChatSummaryComponent';
import { ReloadButton} from './ReloadButtonComponent';
import { PauseButton } from './PauseChatbot';
import { fetchWithCredentials } from './config';

const getStudentChats = async (date, query) => {
  try {
    const queryString = new URLSearchParams({
        date: encodeURIComponent(date),
        query: encodeURIComponent(query)
    }).toString();

    const response = await fetchWithCredentials(`/database/get-teachers-students-latest-chats?${queryString}`, {
        method: 'GET'
    });

    if (response.ok) {
        const data = await response.json();
        return data;
    } else {
        console.error('Failed to fetch student chats');
        return [];
    }
  } catch (error) {
      console.error('Failed to fetch student chats:', error);
      return [];
  }
}

function Teacherwindow() {

    const [recentchats, setRecentchats] = useState([]);
    const [spinning, setSpinning] = useState(false);
    const [date, setDate] = useState(new Date());
    const [query, setQuery] = useState('alla');

    useEffect(() => {
      fetchChats();
    }, [date, query]);

    const fetchChats = async () => {
      const formattedDate = date.toISOString().split('T')[0];
      const data = await getStudentChats(formattedDate, query);
      setRecentchats(data); // Update the state with fetched data
    };

    const reload = async () => {
      setSpinning(true);
      try {
          await fetchChats();
      } finally {
          setSpinning(false);
      }
  };

    const handleDateChange = (change) => {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() + change);
      
      const dateNow = new Date();

      if (newDate > dateNow) {
        newDate.setTime(dateNow.getTime());
      }

      setDate(newDate);
    };

    const formatDate = (date) => {
      return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    };

    const handleInputChange = (event) => {
      setQuery(event.target.value);
    };

    // Handle form submission
    const handleSubmit = (event) => {
      event.preventDefault();
      reload(); // Trigger reload when form is submitted
    };

    return (
      <>
        <div className="relative mx-2 flex flex-row items-center justify-between space-x-6">
          <div className="flex flex-row items-center justify-left">
            <div className="flex items-center justify-between mt-2 mr-4">
              <button onClick={() => handleDateChange(-1)} className="w-12 h-12 bg-gray-300 rounded-full shadow-md hover:bg-gray-400 text-xl font-bold">&lt;</button>
              <span className="flex-1 text-lg text-center mx-2">{formatDate(date)}</span>
              <button onClick={() => handleDateChange(1)} className="w-12 h-12 bg-gray-300 rounded-full shadow-md hover:bg-gray-400 text-xl font-bold">&gt;</button>
            </div>
            <form onSubmit={handleSubmit} className="flex items-center justify-between mt-2 mr-4">
              <input
                id="usernameInput"
                type="text"
                placeholder="Sök efter elev eller 'alla'"
                value={query}
                onChange={handleInputChange}
                className="p-2 border border-gray-400 rounded-md text-black"
              />
            </form>    
          </div>
          <div className="relative mx-2 flex flex-row items-center justify-end space-x-6">
            <PauseButton/>
            <ReloadButton spinning={spinning} onClick={reload} />
          </div>
        </div>
        <div className="flex-1 p-2 overflow-y-auto border-b border-gray-200">
          {recentchats.map((message, index) => (
            <Chat key={index} role={"StudentChats"} content={`**${message.fornamn} ${message.efternamn ? message.efternamn.slice(0, 2) : ''}:** ${message.sammanfattning || ''}`} index={index} />
          ))}
        </div>
      </>
    );
};

export default Teacherwindow;