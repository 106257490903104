// MessageUtils.js
import React from 'react';
import { InlineMath, BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';

// Utility function to parse Markdown-like syntax
const parseMarkdown = (text) => {

  // Replace bold syntax with <strong> tags
  text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

  // Replace newlines with <br> tags
  text = text.replace(/\n/g, '<br>');

  return text;
};

// MessageContent Component
export const MessageLatexParser = ({ content }) => {
  // Split content for LaTeX rendering and Markdown parsing
  const parts = content.split(/(\\\[\s.*?\s\\\]|\$.*?\$|\\\(.*?\\\))/g).filter(Boolean);

  return (
    <>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part.startsWith('\\[') && part.endsWith('\\]') ? (
            <BlockMath math={part.slice(2, -2)} />
          ) : part.startsWith('\\(') && part.endsWith('\\)') ? (
            <InlineMath math={part.slice(2, -2)} />
          ) : part.startsWith('$') && part.endsWith('$') ? (
            <InlineMath math={part.slice(1, -1)} />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: parseMarkdown(part) }} />
          )}
        </React.Fragment>
      ))}
    </>
  );
};
