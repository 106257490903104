import React from 'react';
import { MessageLatexParser } from './MessageUtils';

const getMessageClass = (role) => {
    switch (role) {
      case 'user':
        return 'ml-auto bg-blue-500 text-white max-w-[75%] w-max break-words';
      case 'assistant':
        return 'mr-auto bg-gray-100 text-black max-w-[75%] w-max break-words';
      default:
        return 'mr-auto bg-gray-100 text-black max-w-[75%] w-max break-words';
    }
  };

export const Message = ({ role, content, index }) => {
    const messageClass = getMessageClass(role);
    
    return(
    <div
    key={index}
    className={`p-2 rounded-lg my-1  ${messageClass}`}
    >
    <MessageLatexParser content={content} />
    </div>
    );
};