import React, { useState } from 'react';
import './index.css';
import Login from './Login';
import Content from './RenderContent';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true); // Set login status to true
  };

  const handleLogout = () => {
    setIsLoggedIn(false); // Set login status to false
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">

      <header className="fixed top-0 left-0 w-full h-16 flex items-center justify-between bg-gray-800 text-white px-10 py-4 shadow-lg">
        <h1 className="text-2xl font-bold">MatemAI</h1>
        <Login onLogin={handleLogin} onLogout={handleLogout} isLoggedIn={isLoggedIn} />
      </header>

      <main className="pt-20 flex-grow">
        <Content isLoggedIn={isLoggedIn}/>
      </main>

      <footer className="p-10 text-white mt-4 text-center">
        <p>
          <a href="mailto:kontakt@matemai.se" className="text-gray-400 text-lg">
            MatemAI är just nu i alfa-stadiet. För samtliga frågor och synpunkter om appen, samt information om personuppgifter. Maila: kontakt@matemai.se
          </a>
        </p>
      </footer>
    </div>
  );
}

export default App;