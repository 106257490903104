import React, {useState, useEffect} from 'react';
import { fetchWithCredentials } from './config'

export const PauseButton = () => {
    const [active, setActive] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchWithCredentials('/database/get-pause-unpause-chatbot', {
                    method: 'GET'
                });
            
                if (response.ok) {
                    const data = await response.json();
                    if (data[0].aktiv === 0) {
                        setActive(false);
                    } else {
                        setActive(true);
                    }
                } else {
                    console.error('Failed to fetch data from the server:', response.status);
                }
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };
        fetchData();
      }, []);

    const handleClick = async () => {
        try {
            const response = await fetchWithCredentials('/database/set-pause-unpause-chatbot', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ value: !active })
            });
        
            if (response.ok) {
                setActive(!active);
            } else {
                console.error('Failed to update the pause/unpause status:', response.status);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    return (
        <button 
            onClick={handleClick} 
            className={`relative ml-auto top-2 right-2 w-12 h-12 flex items-center justify-center bg-green-500 text-white rounded-full shadow-md hover:bg-green-600 ${!active ? 'animate-flash' : ''}`}>
        </button>
    );
}