import React from 'react';
import { MessageLatexParser } from './MessageUtils';

export const Chat = ({ role, content, index }) => {
    
    return(
    <div
    key={index}
    className={`p-2 rounded-lg my-1 mr-auto bg-gray-100 text-black max-w-[90%] w-max break-words`}
    >
    <MessageLatexParser content={content} />
    </div>
    );
};