import React, { useState, useRef, useEffect } from 'react';
import { Message } from './MessageComponent';
import { RestartMessages } from './RestartConversation';
import { fetchWithCredentials } from './config';

function Chatwindow() {
  const startMessage = "Väkommen till MatemAI. Testa att ge mig en matteuppgift, hitta på några övningsuppgifter eller fråga om något matematiskt du inte känner dig säker på.";
  
  const [messages, setMessages] = useState([
    { role: 'assistant', content: startMessage }
  ]);

  const clearMessages = () => { // Only UI
    setMessages([{ role: 'assistant', content: startMessage }]);
  };

  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const chatEndRef = useRef(null); // For scrolling down
  
  const sendMessage = async () => {
    const newMessage = inputRef.current.value.trim();

    try {
      const response = await fetchWithCredentials('/database/get-pause-unpause-chatbot', {
        method: 'GET'
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const result = await response.json();
      const isActive = result[0].aktiv === 1;

      if (newMessage && !loading  && isActive) {
        setMessages(prevMessages => [
          ...prevMessages,
          { role: 'user', content: newMessage }
        ]);
        inputRef.current.value = '';
        setLoading(true);

        try {
          const postResponse = await fetchWithCredentials('/openai/chat/message-assistant', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ message: newMessage })
        });

        if (!postResponse.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await postResponse.json();
        const assistantMessage = data.content;

          setMessages(prevMessages => [
            ...prevMessages,
            { role: 'assistant', content: assistantMessage }
          ]);

        } catch (error) {
          console.error('Error handling message:', error);
          setMessages(prevMessages => [
            ...prevMessages,
            { role: 'assistant', content: 'Internal server error' }
          ]);
        } finally {
          setLoading(false);
        }
      }

    } catch (error) {
      console.error('There was a problem with the axios operation:', error);
    }
  };

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <>
      <div className="relative mx-2 flex flex-row items-center justify-end space-x-6">
        <RestartMessages clearMessages={clearMessages} />
      </div>
      <div className="flex-1 p-2 overflow-y-auto border-b border-gray-200">
        {messages.map((message, index) => (
          <Message key={index} role={message.role} content={message.content} index={index} />
        ))}
        {loading && (
          <div className="flex justify-center items-center my-2">
            <div className="relative">
              <div className="w-3 h-3 bg-blue-500 rounded-full absolute animate-ping"></div>
              <div className="w-3 h-3 bg-blue-500 rounded-full absolute animate-pulse"></div>
            </div>
          </div>
        )}
        <div ref={chatEndRef} />
      </div>
      <div className="flex border-t border-gray-200 p-2 bg-white">
        <textarea
          ref={inputRef}
          placeholder="Skriv ditt matteproblem här..."
          className="flex-1 p-2 border border-gray-300 rounded-md resize-none h-18"
        ></textarea>
        <button
          id="messageButton"
          onClick={sendMessage}
          className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600"
        >
          Skicka
        </button>
      </div>
    </>
  );
}

export default Chatwindow;
