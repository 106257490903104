import React, { useState, useEffect } from 'react';
import Teacherwindow from './Teacherwindow';
import Chatwindow from './Chatwindow';
import { fetchWithCredentials } from './config';

const Content = ({ isLoggedIn }) => {
  const [userMode, setUserMode] = useState(null);

  useEffect(() => {
    if (isLoggedIn) {
      const fetchUserMode = async () => {
        try {
          const response = await fetchWithCredentials('/usermanager/role', {
              method: 'GET'
          });
      
          if (response.ok) {
              const data = await response.json();
              setUserMode(data.role);
          } else {
              console.error('Failed to fetch user mode:', response.status);
          }
        } catch (error) {
            console.error('Error fetching user mode:', error);
        }
      };

      fetchUserMode();
    }
  }, [isLoggedIn]);

  let content = null;
  switch (userMode) {
    case 'larare':
      content = <Teacherwindow />;
      break;
    case 'elev':
      content = <Chatwindow />;
      break;
    default:
      content = null;
  }

  return (
    <div className="w-[100vh] h-[70vh] lg:h-175 flex flex-col border border-gray-300 rounded-lg bg-white shadow-md mx-auto my-4">
      {content}
    </div>
  );
};

export default Content;
